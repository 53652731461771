import React from 'react';
import { graphql } from 'gatsby';
// import get from 'lodash/get';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import ArticlePreview from '../components/article-preview';

const BlogLife = ({ data, location }) => {

    const posts = data.allContentfulArticle.nodes;

    return (
        <Layout location={location}>
            <Seo title="生活" />
            <Hero title="生活" />
            <ArticlePreview posts={posts} />
        </Layout>
    );

}

export default React.memo(BlogLife);

export const pageQuery = graphql`
    query BlogLifeQuery {
        allContentfulArticle(
            limit: 36
            filter: {tags: {eq: "生活"}}
            sort: {fields: publishDate, order: DESC}
        ) {
            nodes {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                heroImage {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        height: 212
                        width: 424
                    )
                }
                description {
                    raw
                }
            }
        }
    }
`
